import NoResults from "../../../../layouts/NoResults";
import ListingComponent from "../../../reusable/ListingComponent";

const AccommodationListing = ({accommodations}) => {
  
  return (
    <NoResults errorMessage={"Coming soon..."} />
  );
};

export default AccommodationListing